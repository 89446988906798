/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.text-right {
  text-align: right;
}
.ml-10 {
  margin-left: 10px;
}

.mat-mdc-table {
  width: 100%;
}

/*change color*/
.mat-toolbar.mat-primary {
  background: linear-gradient(60deg, #110133, #00918e);
}
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card-header,
.header {
  background: #00918e;
  color: white;
  margin-bottom: 10px;
}
.header {
  padding-left: 10px;
  margin: 5px !important;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-mini-fab.mat-primary,
.mat-mdc-raised-button.mat-primary {
  background-color: #00918e;
}
.mat-mdc-list-item {
  border-bottom: 1px solid lightgray;
  mat-icon {
    color: #110133;
    margin-right: 15px;
  }
}
.main-container {
  position: absolute;
  top: 64px;
  left: 250px;
  right: 0;
  bottom: 0;
  padding: 20px;
  background: #fbfbfb;
}

.mb-20 {
  margin-bottom: 20px;
}

@media screen and (max-width: 992px) {
  .main-container {
    left: 0px;
  }
  .push-right {
    #sidebar {
      left: 0 !important;
    }
  }
  .cards-container {
    .mat-mdc-card {
      width: 100% !important;
    }
  }
}
.m-0 {
  margin: 0;
}
.p-0 {
  padding: 0;
}
.mt5 {
  padding-top: 5px;
}
.mt10 {
  padding-top: 10px;
}
.mt15 {
  padding-top: 15px;
}
.mt20 {
  padding-top: 20px;
}
.mb5 {
  padding-bottom: 5px;
}
.mb10 {
  padding-bottom: 10px;
}
.mb15 {
  padding-bottom: 15px;
}
.mb20 {
  padding-bottom: 20px;
}
.ml5 {
  padding-left: 5px;
}
.ml10 {
  padding-left: 10px;
}
.ml15 {
  padding-left: 15px;
}
.ml20 {
  padding-left: 20px;
}
.mr5 {
  padding-right: 5px;
}
.mr10 {
  padding-right: 10px;
}
.mr15 {
  padding-right: 15px;
}
.mr20 {
  padding-right: 20px;
}

.pt5 {
  padding-top: 5px;
}
.pt10 {
  padding-top: 10px;
}
.pt15 {
  padding-top: 15px;
}
.pt20 {
  padding-top: 20px;
}
.pb5 {
  padding-bottom: 5px;
}
.pb10 {
  padding-bottom: 10px;
}
.pb15 {
  padding-bottom: 15px;
}
.pb20 {
  padding-bottom: 20px;
}
.pl5 {
  padding-left: 5px;
}
.pl10 {
  padding-left: 10px;
}
.pl15 {
  padding-left: 15px;
}
.pl20 {
  padding-left: 20px;
}
.pr5 {
  padding-right: 5px;
}
.pr10 {
  padding-right: 10px;
}
.pr15 {
  padding-right: 15px;
}
.pr20 {
  padding-right: 20px;
}
.full-width {
  width: 100%;
}
a:hover {
  background: #00918e !important;
  color: white !important;
}
.cards-container {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  margin-bottom: 15px;
  /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
  mat-card-header {
    background: transparent;
  }
  .icon-lg {
    font-size: 40px;
  }
  .mat-mdc-card {
    color: #fff;
    width: 30%;
    margin: 5px;
    flex-grow: 1;
    .mat-mdc-card-header {
      width: 100%;
    }
    .mat-mdc-card-title {
      font-size: 40px !important;
    }
    .mat-mdc-card-subtitle {
      color: #fff;
    }

    .mat-mdc-card-actions {
      a {
        text-decoration: none;
        cursor: pointer;
        color: #fff;
      }
      a.odd:hover {
        background: transparent !important;
        text-decoration: underline;
        font-weight: 500;
      }
      a.even {
        color: #110133 !important;
      }
      a.even:hover {
        background: transparent !important;
        text-decoration: underline;
        font-weight: 500;
      }
    }
  }
}

.mat-mdc-card.odd {
  background: linear-gradient(60deg, #110133, #00918e);
}

.mat-mdc-card.even {
  background: linear-gradient(60deg, #110133, #00918e);
}
.alert-error {
  background: red;
  color: white;
}
.alert-success {
  background: #00918e;
  color: white;
}
img {
  width: 100%;
  height: 250px;
  max-width: 615px;
  object-fit: cover;
  margin-bottom: 10px;
}
.branding img {
  min-width: 150px;
  max-width: 150px;
  height: 150px;
}
.card-button {
  margin-top: -10px;
  align-self: center;
  justify-content: center;
  button {
    width: 100%;
  }
}
